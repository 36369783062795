import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoDiamond from "../components/LogoDiamond"
import { motion } from 'framer-motion';
import FadeUp from "../components/fadeUp";
import FeatherIcon from 'feather-icons-react';
import LogoTag from "../components/LogoTag"; 
import { Link} from 'gatsby';
import {AnchorLink} from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"


const IndexPage = () => (

  <Layout>
    <Seo title="Home" />
<Helmet>
  <body className="body-dark"></body>
</Helmet>

<section className="bg-gradient-to-t from-owlgray to-owlgray-light h-screen/2 md:h-screen/2 flex flex-col justify-end items-center ">
      <motion.div
        initial={{y: 50, opacity: 0,}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 2, delay: 0.5, ease: "easeOut" }}   
        className="pb-8 mx-auto"             
      >
        <h1 className="w-full sm:w-1/3 display-1 mx-auto lg:text-5xl text-2xl my-8 p-4 text-center text-owlwhite" >
          We are a <span className="text-owlyellow"><strong>design</strong></span> and <span className="text-owlyellow"><strong>marketing</strong></span> agency who believe great ideas start with you.
        </h1>
      </motion.div>     
</section>    

<motion.section 
  className="grid grid-cols-1 h-screen header flex items-top "
  initial={{y: 75, opacity: 0}}
  animate={{y: 0, opacity: 1}}
  transition={{duration: 1, type: "spring", ease: 'easeOut', bounce: 0.5 }} 
>
    <AnchorLink to="/#about" className="w-8 h-12 mx-auto mt-16 animate-bounce">
    <LogoDiamond className="fill-owlred hover:fill-owlred-dark"/>  
    </AnchorLink>
</motion.section>          




  <section className="section bg-gradient-to-b from-owlgreen to-owlgreen-dark text-owlwhite h-auto py-32" id="about"> 

      <FadeUp>
        <div className="w-full lg:w-2/4 mb-32 mx-auto text-center">
            <p className="display-1 text-2xl md:text-4xl my-4 ">Mixing creativity with experience, we work with you to help you connect with your customers and realise the potential of your business.</p>
        </div>  
      </FadeUp>


        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-8">
              <FadeUp className="rounded-md border border-owlwhite p-4">    
                <FeatherIcon icon="globe" size="48" stroke="#fff" className="float-right"/>         
                <h1 className="display-1 text-4xl">Website Design</h1>
                <hr />                
                <ol className="list-disc">
                  <li>To suit any budget</li>
                  <li>Sites that grow with your business</li>
                  <li>Individually designed</li>                                
                  <li>Hosting included</li>                
                </ol>
              </FadeUp>               
              <FadeUp className="rounded-md border border-owlwhite p-4">                  
                <FeatherIcon icon="droplet" size="48" stroke="#fff" className="float-right"/>         
                <h1 className="display-1 text-4xl">Marketing</h1>
                <hr />
                <ol className="list-disc">                
                  <li>Marketing plans and strategy</li>
                  <li>Copy writing</li>
                  <li>Print media</li>                                
                  <li>Direct mail specialists</li>                     
                </ol>
              </FadeUp>
              <FadeUp className="rounded-md border border-owlwhite p-4">                   
                <FeatherIcon icon="hexagon" size="48" stroke="#fff" className="float-right"/>         
                <h1 className="display-1 text-4xl">Brand Identity</h1>
                <hr />                
                <ol className="list-disc">
                  <li>Brand creation</li>
                  <li>Logo design</li>
                  <li>Bespoke graphics</li>                                
                  <li>Future proof concepts</li>                      
                </ol>
              </FadeUp>
              <FadeUp className="rounded-md border border-owlwhite p-4">             
                <FeatherIcon icon="twitter" size="48" stroke="#fff" className="float-right"/>         
                <h1 className="display-1 text-4xl">Digital  &amp; Social</h1>
                <hr />                
                <ol className="list-disc">                
                  <li>Multi-platform marketing</li>
                  <li>Content creation</li>
                  <li>Email marketing</li>                                
                  <li>Landing pages &amp; form creation</li>                  
                </ol>
              </FadeUp>
          </div>

          <div className="md:w-full mx-auto mt-16 flex justify-center">
        <StaticImage
            src="../images/2owls_footer2.png"
            alt="2 owls and a whiteboard with 2 mugs"
            objectFit="cover"
            objectPosition="50% 50%"
          />               
    </div>            

          <FadeUp className="flex items-center justify-center mt-16">
            <Link to="/contact" className="btn-yellow w-48 py-4 mt-5" target="_blank" rel="noreferrer">
              <div className="display-3">Get in touch
              <FeatherIcon icon="external-link" size="24" className="stroke-owlwhite -mt-0.5 ml-3 float-right"/>    
              </div>
            </Link>
          </FadeUp>  

        </div>
</section>    


<section className="section feather-bg text-owlgreen h-auto py-32">
  <div className="container">
    <FadeUp className="grid grid-cols-1 lg:grid-cols-2 gap-8">

    <motion.div 
        whileHover={{ backgroundColor: "#ECBE6D", backgroundSize: "105%", scale: 1.03, boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)" }}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "100%", backgroundColor: "#fff",}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="h-full items-center rounded-lg"
     >

        <Link className={`card work`} to={`/work/`}>

          <motion.div 
            initial={{y: 20, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{delay: 0.5, duration: 0.8, type: "spring", ease: 'easeOut', bounce: 0.4 }} 
            className="work-title mix-blend-multiply">

            <h1 className={`text-lg sm:text-xl`}>Work</h1>

            <motion.hr 
              initial={{width: 0}}
              animate={{width: "100%"}}
              transition={{duration: 1, delay: 0.7}}                  
            />

          </motion.div>
        </Link>
    </motion.div>   

    <motion.div 
        whileHover={{ backgroundColor: "#ECBE6D", backgroundSize: "105%", scale: 1.03, boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)" }}
        whileTap={{ scale: 1, backgroundColor: "#cdcdcd" }}
        initial={{y: 100, opacity: 0, backgroundSize: "100%", backgroundColor: "#fff"}}
        animate={{y: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }} 
        className="h-full items-center rounded-lg"
     >

        <Link className={`card contact`} to={`/contact/`}>

          <motion.div 
            initial={{y: 20, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{delay: 0.5, duration: 0.8, type: "spring", ease: 'easeOut', bounce: 0.4 }} 
            className="work-title mix-blend-multiply">

            <h1 className={`text-lg sm:text-xl`}>Contact</h1>

            <motion.hr 
              initial={{width: 0}}
              animate={{width: "100%"}}
              transition={{duration: 1, delay: 0.7}}                  
            />

          </motion.div>
        </Link>
    </motion.div>   
   
    </FadeUp>  
    </div>
</section>

</Layout>

)


export default IndexPage
