import React from 'react';
import { motion } from 'framer-motion';

const wrapper = {
    visible: { 
        opacity: 1, y: 0,
        transition: {
            when: "beforeChildren",
            delayChildren: 0.1,
        },
    },
    hidden: { 
        opacity: 1, y: 10,
        transition: {
            when: "afterChildren",
        },
    },

  }

const outline = {
    visible: {opacity: 1, strokeOpacity: 1, pathLength: 1, pathOffset: 0,
        transition: {duration: 3, ease: 'easeOut'}  
    },
    hidden: { opacity: 0, strokeOpacity: 0, pathLength: 0, pathOffset: 1 },
  }

const fill = {
    visible: {opacity: 1,
        transition: {duration: 4, delay: 3, ease: 'easeOut'}  
    },
    hidden: { opacity: 0 },
  }


const LogoTag = (props) => (

    <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 456.82 874.46"

    className={props.className}
    initial="hidden"
    whileInView="visible" 
    viewport={{ once: true, amount: 0 }}            
    variants={wrapper}
    >

<motion.path d="M62.5 763.62c-33.1 0-56.47 23.37-56.47 55.17s23.37 55.17 56.47 55.17 56.47-23.37 56.47-55.17-23.37-55.17-56.47-55.17Zm0 86c-18.01 0-29.86-12.98-29.86-30.83s11.85-30.83 29.86-30.83 29.86 12.98 29.86 30.83-11.85 30.83-29.86 30.83ZM241.31 832.91c0 12.33-6 16.71-13.95 16.71s-13.95-4.38-13.95-16.71v-67.18h-26.29v67.18c0 12.33-6.17 16.71-13.95 16.71s-13.95-4.38-13.95-16.71v-67.18h-26.29v67.18c0 28.4 16.55 41.05 37.97 41.05 12.82 0 23.04-5.03 29.37-14.6 6.33 9.57 16.55 14.6 29.37 14.6 21.42 0 37.97-12.66 37.97-41.05v-67.18h-26.29v67.18ZM313.19 765.73H286.9v106.12h76.43v-24.34h-50.14v-81.78zM416.71 807.43c-9.9-1.79-18.01-3.41-18.01-10.38 0-5.52 3.73-10.87 13.14-10.87s12.98 4.87 13.14 11.03h24.66c-.81-20.12-15.9-33.59-38.13-33.59s-38.13 14.28-38.13 34.4c0 25.8 19.31 29.37 34.4 32.13 9.74 1.79 17.85 3.41 17.85 10.38s-4.06 10.87-14.28 10.87-14.28-4.22-14.28-10.87h-25.64c.49 20.61 16.23 33.43 39.92 33.43s39.92-13.31 39.92-34.4c0-25.8-19.31-29.37-34.56-32.13ZM52.27 318.27l35.65 35.65a9.73 9.73 0 0 1 2.01 10.84l-35.65 80.2c-3.43 7.71-14.37 7.71-17.79 0L.84 364.75a9.73 9.73 0 0 1 2.01-10.84l35.65-35.65c3.8-3.8 9.97-3.8 13.77 0Z" 
    fill="none"
    stroke={props.stroke}
    strokeMiterlimit={10} 
    strokeWidth={4}      
    variants={outline} 
/>

<motion.path d="M332.42 454.33c77.88-70.31 124.4-129.81 124.4-225 0-76.75-27.63-140.74-77.6-181.45-5.1-4.16-4.73-12.06.76-15.68l21.72-14.33C409.76 12.54 406 0 396.35 0H231.48C91.06.15 6.82 95.31 6.82 237.98c0 5.38 4.36 9.74 9.74 9.74H67.4c5.38 0 9.74-4.36 9.74-9.74v-5.41c0-97.36 58.41-165.51 154.69-165.51s151.44 65.99 151.44 162.26c0 70.31-41.11 126.56-102.77 181.73L8.92 658.04a9.742 9.742 0 0 0-3.19 7.2v46.54c0 5.38 4.36 9.74 9.74 9.74h422.96c5.38 0 9.74-4.36 9.74-9.74v-45.43c0-5.38-4.36-9.74-9.74-9.74H133.8c-8.91 0-13.14-10.98-6.53-16.96l205.15-185.33Z" 
    fill="none"
    stroke={props.stroke}
    strokeMiterlimit={10} 
    strokeWidth={4}      
    variants={outline} 
/>


<motion.path d="M222.6 161.17c-37.29 0-67.51 30.23-67.51 67.51s30.23 67.51 67.51 67.51 67.51-30.23 67.51-67.51-30.23-67.51-67.51-67.51Zm0 91.02c-12.98 0-23.51-10.52-23.51-23.51s10.52-23.51 23.51-23.51 23.51 10.52 23.51 23.51-10.52 23.51-23.51 23.51Z" 
    fill="none"
    stroke={props.stroke}
    strokeMiterlimit={10} 
    strokeWidth={4}      
    variants={outline}
/>



<motion.path d="M62.5 763.62c-33.1 0-56.47 23.37-56.47 55.17s23.37 55.17 56.47 55.17 56.47-23.37 56.47-55.17-23.37-55.17-56.47-55.17Zm0 86c-18.01 0-29.86-12.98-29.86-30.83s11.85-30.83 29.86-30.83 29.86 12.98 29.86 30.83-11.85 30.83-29.86 30.83ZM241.31 832.91c0 12.33-6 16.71-13.95 16.71s-13.95-4.38-13.95-16.71v-67.18h-26.29v67.18c0 12.33-6.17 16.71-13.95 16.71s-13.95-4.38-13.95-16.71v-67.18h-26.29v67.18c0 28.4 16.55 41.05 37.97 41.05 12.82 0 23.04-5.03 29.37-14.6 6.33 9.57 16.55 14.6 29.37 14.6 21.42 0 37.97-12.66 37.97-41.05v-67.18h-26.29v67.18ZM313.19 765.73H286.9v106.12h76.43v-24.34h-50.14v-81.78zM416.71 807.43c-9.9-1.79-18.01-3.41-18.01-10.38 0-5.52 3.73-10.87 13.14-10.87s12.98 4.87 13.14 11.03h24.66c-.81-20.12-15.9-33.59-38.13-33.59s-38.13 14.28-38.13 34.4c0 25.8 19.31 29.37 34.4 32.13 9.74 1.79 17.85 3.41 17.85 10.38s-4.06 10.87-14.28 10.87-14.28-4.22-14.28-10.87h-25.64c.49 20.61 16.23 33.43 39.92 33.43s39.92-13.31 39.92-34.4c0-25.8-19.31-29.37-34.56-32.13ZM52.27 318.27l35.65 35.65a9.73 9.73 0 0 1 2.01 10.84l-35.65 80.2c-3.43 7.71-14.37 7.71-17.79 0L.84 364.75a9.73 9.73 0 0 1 2.01-10.84l35.65-35.65c3.8-3.8 9.97-3.8 13.77 0Z" 
    fill={props.fill}
    strokeMiterlimit={10} 
    strokeWidth={1}      
    variants={fill} />

<motion.path d="M332.42 454.33c77.88-70.31 124.4-129.81 124.4-225 0-76.75-27.63-140.74-77.6-181.45-5.1-4.16-4.73-12.06.76-15.68l21.72-14.33C409.76 12.54 406 0 396.35 0H231.48C91.06.15 6.82 95.31 6.82 237.98c0 5.38 4.36 9.74 9.74 9.74H67.4c5.38 0 9.74-4.36 9.74-9.74v-5.41c0-97.36 58.41-165.51 154.69-165.51s151.44 65.99 151.44 162.26c0 70.31-41.11 126.56-102.77 181.73L8.92 658.04a9.742 9.742 0 0 0-3.19 7.2v46.54c0 5.38 4.36 9.74 9.74 9.74h422.96c5.38 0 9.74-4.36 9.74-9.74v-45.43c0-5.38-4.36-9.74-9.74-9.74H133.8c-8.91 0-13.14-10.98-6.53-16.96l205.15-185.33Z" 
    fill={props.fill}
    strokeMiterlimit={10} 
    strokeWidth={1}      
    variants={fill} />


<motion.path d="M222.6 161.17c-37.29 0-67.51 30.23-67.51 67.51s30.23 67.51 67.51 67.51 67.51-30.23 67.51-67.51-30.23-67.51-67.51-67.51Zm0 91.02c-12.98 0-23.51-10.52-23.51-23.51s10.52-23.51 23.51-23.51 23.51 10.52 23.51 23.51-10.52 23.51-23.51 23.51Z" 
    fill={props.fill}
    strokeMiterlimit={10} 
    strokeWidth={1}      
    variants={fill} />





</motion.svg>





)

export default LogoTag;